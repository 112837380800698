import dynamic from "next/dynamic";

import st from "@styles/components/Header.module.scss";
import Button from "@items/Button";
import useLanguage from "@hooks/useLanguage";
import { DropdownIcons } from "@assets/icons/dropdown";
import { useUserSession } from "@hooks/useUserSession";
import { forwardRef, memo } from "react";
import Link from "next/link";
import ProfileDropdown from "./header/profile-dropdown";

const DropDown = dynamic(() => import("@items/DropDown"));

const menu = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.29754 22.6927H13.5851C14.3179 22.6927 14.912 22.0987 14.912 21.3659C14.912 20.6332 14.3179 20.0391 13.5851 20.0391H4.29754C3.56475 20.0391 2.97073 20.6332 2.97073 21.3659C2.97073 22.0987 3.56475 22.6927 4.29754 22.6927ZM4.29754 15.5555H25.5262C26.259 15.5555 26.853 14.9615 26.853 14.2287C26.853 13.4959 26.259 12.9019 25.5262 12.9019H4.29749C3.5647 12.9019 2.97068 13.4959 2.97068 14.2287C2.97068 14.9615 3.56475 15.5555 4.29754 15.5555ZM4.29754 8.41828H25.5262C26.259 8.41828 26.853 7.82425 26.853 7.09146C26.853 6.35867 26.259 5.76465 25.5262 5.76465H4.29749C3.5647 5.76465 2.97068 6.35867 2.97068 7.09146C2.97068 7.82425 3.56475 8.41828 4.29754 8.41828Z"
      fill="#ffffff"
    />
  </svg>
);

const cassino = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1387_32705)">
      <path
        d="M26.1953 1.89526L15.6369 0.0335359C14.4494 -0.175863 13.3169 0.617106 13.1075 1.80465L13.0076 2.37137C14.5345 2.5595 15.8245 3.65948 16.2307 5.17515L16.8759 7.58331C17.2176 7.43516 17.6049 7.38179 17.9991 7.45129C18.747 7.5832 19.3209 8.12149 19.5346 8.7989C19.9672 8.23546 20.6906 7.92587 21.4385 8.05778C22.509 8.24656 23.2238 9.26741 23.0351 10.3379C22.6249 12.664 20.1288 14.5336 18.9442 15.3026L20.7528 22.0523C20.8498 22.4141 20.8902 22.7777 20.8813 23.1345L22.4029 23.4028C23.5904 23.6122 24.7229 22.8192 24.9323 21.6317L27.9663 4.42478C28.1758 3.23713 27.3828 2.10466 26.1953 1.89526Z"
        fill="#434970"
      />
      <path
        d="M14.649 5.59896C14.3876 4.62345 13.5054 3.98005 12.5413 3.9801C12.3543 3.9801 12.1641 4.00433 11.9748 4.05502L1.61888 6.82987C0.454087 7.14197 -0.237163 8.33924 0.0749387 9.50403L4.5971 26.3811C4.85851 27.3566 5.74067 27.9999 6.70476 27.9999C6.89179 27.9999 7.08199 27.9757 7.27121 27.925L17.6272 25.1501C18.792 24.838 19.4832 23.6408 19.1711 22.476L14.649 5.59896ZM6.59041 17.1353C6.41481 16.9809 6.34924 16.736 6.42405 16.5145L8.00758 11.8257C8.14003 11.4335 8.62905 11.3024 8.93989 11.5759L12.6557 14.8448C12.8313 14.9993 12.8969 15.2441 12.822 15.4656L11.2385 20.1544C11.1061 20.5466 10.617 20.6777 10.3062 20.4042L6.59041 17.1353Z"
        fill="#434970"
      />
    </g>
    <defs>
      <clipPath id="clip0_1387_32705">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const profile = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5001 16.3334C7.27842 16.3334 4.66675 18.945 4.66675 22.1667C4.66675 24.0997 6.23375 25.6667 8.16675 25.6667H19.8334C21.7664 25.6667 23.3334 24.0997 23.3334 22.1667C23.3334 18.945 20.7217 16.3334 17.5001 16.3334H10.5001Z"
      fill="#434970"
    />
    <path
      d="M14.0001 2.33337C10.7784 2.33337 8.16675 4.94505 8.16675 8.16671C8.16675 11.3884 10.7784 14 14.0001 14C17.2217 14 19.8334 11.3884 19.8334 8.16671C19.8334 4.94505 17.2217 2.33337 14.0001 2.33337Z"
      fill="#434970"
    />
  </svg>
);

const chat = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 17.1538V7.96667C0.5 5.35309 0.5 4.0463 1.00864 3.04804C1.45605 2.16995 2.16995 1.45605 3.04804 1.00864C4.0463 0.5 5.35309 0.5 7.96667 0.5H14.0333C16.6469 0.5 17.9537 0.5 18.952 1.00864C19.83 1.45605 20.544 2.16995 20.9914 3.04804C21.5 4.0463 21.5 5.35309 21.5 7.96667V9.36667C21.5 11.9802 21.5 13.287 20.9914 14.2853C20.544 15.1634 19.83 15.8773 18.952 16.3247C17.9537 16.8333 16.6469 16.8333 14.0333 16.8333H9.08569C8.50092 16.8333 8.20853 16.8333 7.93418 16.9008C7.69096 16.9605 7.45895 17.0591 7.24706 17.1926C7.00804 17.3432 6.80502 17.5536 6.399 17.9745L5.31502 19.098C3.80995 20.6579 3.05741 21.4379 2.40915 21.4971C1.84689 21.5484 1.29439 21.3254 0.925412 20.898C0.5 20.4053 0.5 19.3215 0.5 17.1538ZM5.16667 6.33333C5.16667 5.689 5.689 5.16667 6.33333 5.16667H15.6667C16.311 5.16667 16.8333 5.689 16.8333 6.33333C16.8333 6.97767 16.311 7.5 15.6667 7.5H6.33333C5.689 7.5 5.16667 6.97767 5.16667 6.33333ZM6.33333 9.83333C5.689 9.83333 5.16667 10.3557 5.16667 11C5.16667 11.6443 5.689 12.1667 6.33333 12.1667H9.83333C10.4777 12.1667 11 11.6443 11 11C11 10.3557 10.4777 9.83333 9.83333 9.83333H6.33333Z"
      fill="#434970"
    />
  </svg>
);

const sports = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: "translateY(5px)",
    }}
  >
    <g clipPath="url(#clip0_109_7741)">
      <path
        d="M10.8141 1.61554C11.0403 1.31573 11.0039 0.679815 10.9717 0.118853C10.9705 0.0946733 10.9691 0.0710254 10.9678 0.0470093C10.6507 0.0167335 10.3297 0 10.0049 0C8.76009 0 7.56903 0.229319 6.46973 0.645039C7.25391 1.02189 8.46777 1.67053 9.817 2.62892C10.2774 2.23276 10.6246 1.86704 10.8141 1.61554Z"
        fill="#7179A5"
      ></path>
      <path
        d="M15.8808 7.32163C15.3879 7.37731 14.9722 7.4582 14.6167 7.57165C16.3161 10.5388 16.9787 14.2874 17.1408 17.0031C18.8244 15.2876 19.8936 12.9686 19.9947 10.3999C18.8347 8.97403 17.2979 7.16088 15.8808 7.32163Z"
        fill="#7179A5"
      ></path>
      <path
        d="M5.77799 4.76052C6.97204 4.51206 8.09384 3.91108 8.98829 3.27672C7.12232 1.99155 5.56598 1.32459 5.25639 1.19763C3.32049 2.24415 1.77135 3.91157 0.870117 5.93191C2.04155 5.46517 3.31023 5.22771 4.55632 4.99635C4.95944 4.92148 5.37614 4.84411 5.77799 4.76052Z"
        fill="#7179A5"
      ></path>
      <path
        d="M12.6899 5.05128C12.7965 5.1584 12.9028 5.26735 13.0084 5.37835C13.3979 5.78727 13.7514 6.23454 14.0755 6.70774C14.5475 6.52952 15.1032 6.40314 15.7683 6.32794C17.3356 6.15067 18.7292 7.39218 19.9253 8.75901C19.6688 6.68696 18.7802 4.81162 17.456 3.33264C16.7332 3.41975 15.9274 3.56732 15.1062 3.81239C14.5674 3.9733 13.7094 4.4105 12.6899 5.05128Z"
        fill="#7179A5"
      ></path>
      <path
        d="M11.3744 10.2156C11.7846 8.82637 12.2596 7.82494 13.1801 7.17491C12.9039 6.77833 12.6066 6.40615 12.2846 6.06804C12.1356 5.91159 11.9849 5.76049 11.8337 5.61206C8.1407 8.12573 3.08206 12.7432 2.62207 16.744C4.15755 18.424 6.25893 19.5761 8.62454 19.9029C9.48589 18.1881 10.2966 14.7019 10.7785 12.6285C11.01 11.6327 11.2098 10.7728 11.3744 10.2156Z"
        fill="#7179A5"
      ></path>
      <path
        d="M7.187 8.01259C8.47462 6.82606 9.83442 5.76207 11.0781 4.90694C10.6636 4.54016 10.2477 4.20205 9.83949 3.89197C8.80647 4.6685 7.45396 5.43333 5.98174 5.73953C5.56946 5.82532 5.14727 5.90375 4.73912 5.97953C3.1665 6.27157 1.65688 6.5544 0.38734 7.26273C0.140101 8.133 0.00488281 9.05052 0.00488281 10C0.00488281 12.1241 0.669232 14.0922 1.79848 15.7117C2.53005 12.8151 5.03934 9.9916 7.187 8.01259Z"
        fill="#7179A5"
      ></path>
      <path
        d="M11.9395 4.33573C13.1292 3.57728 14.1549 3.05286 14.8203 2.85422C15.4179 2.67576 16.0041 2.54578 16.5592 2.45098C15.2765 1.3363 13.7085 0.541847 11.9775 0.195557C12.0159 0.893946 12.0326 1.6607 11.6125 2.21778C11.4128 2.48232 11.0752 2.84514 10.6357 3.23877C11.0661 3.57372 11.5034 3.939 11.9395 4.33573Z"
        fill="#7179A5"
      ></path>
      <path
        d="M12.3334 10.4987C12.1773 11.0276 11.9803 11.8744 11.7525 12.8548C11.0797 15.7496 10.4359 18.34 9.68408 19.9918C9.79082 19.9952 9.89753 20 10.0051 20C12.3356 20 14.4781 19.2006 16.1783 17.864C16.1111 15.0222 15.4299 11.0025 13.7138 8.01855C13.0482 8.51324 12.6899 9.29145 12.3334 10.4987Z"
        fill="#7179A5"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_109_7741">
        <rect width="20" height="20" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

const Div = ({ ...props }) => <div {...props} />;

export const NavButton = memo(
  forwardRef(function NavButton({ onClick, icon, text, href }, ref) {
    const Wrapper = href ? Link : Div;

    return (
      <Wrapper
        href={href}
        className={st["mobile-menu-button"]}
        onClick={onClick}
        ref={ref}
      >
        <div className={st["mobile-menu-button__icon"]}>{icon}</div>
        <span>{text}</span>
      </Wrapper>
    );
  })
);

export default function MobileNav({ setMenuToggle, setChat }) {
  const { hasToken, signOut: logout } = useUserSession();
  const L = useLanguage(["meta", "common", "UserDisplay"]);

  return (
    <div className={`${st["mobile-nav"]} ${hasToken && "logged-in"}`}>
      <NavButton
        icon={menu}
        text={L("home.mobile_nav.menu")}
        onClick={() => setMenuToggle((item) => !item)}
      />

      <NavButton
        icon={cassino}
        text={L("home.mobile_nav.casino")}
        href={{
          pathname: "/casino",
        }}
      />

      {/* sportsbook */}
      <NavButton
        icon={sports}
        text={L("sports")}
        href={{
          pathname: "/sportsbook",
        }}
      />

      {hasToken && (
        <ProfileDropdown
          trigger={<NavButton icon={profile} text={L("profile")} />}
        />
      )}

      <NavButton
        icon={chat}
        text={L("home.mobile_nav.chat")}
        onClick={() => setChat((item) => !item)}
      />
    </div>
  );
}
